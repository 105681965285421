body, html {
  padding: 0 !important;
  margin: 0 !important;
}

.carouselPosition{
  position: absolute;
  top: 8px;
  left: 24px;
  text-align: left;
}

.text-retro-red{
  color: #c70000 !important;
}

.background-retro-red{
  background-color: #c70000 !important;
}

.background-black{
  background-color: #000 !important;
}

.background-retro-yellow{
  background-color: #ffd100 !important;
  color: #051945 !important;
}

.background-retro-navy{
  background-color: #2A344D !important;
  color: #fff !important;
}

.text-retro-navy{
  color: #2A344D !important;
}

table{
  font-size: 14px;
}

th{
  font-weight: normal;
}

.placental-title{
  font-family: 'Dancing Script', cursive;
  font-size: 48px;
}

.placental-title-big{
  font-family: 'Dancing Script', cursive;
  font-size: 64px !important;
  padding: 0 12px;
}

.placental-title-big-color{
  color: #fcbc11;
}

.placental-modal{
  z-index: 9999;
  background-color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
  overflow-x: hidden;
}

.plc-orders-tbl .badge {
  min-width: 96px;
}

.plc-badge-accepted{
  background: rgba(170, 1, 255, 0.1) !important;
  color: rgba(170, 1, 255, 1.0) !important;
  font-weight: normal;
  padding: 4px 16px;
  font-size: 12px;
}

.plc-btn-accepted{
  background: rgba(170, 1, 255, 0.1);
  color: rgba(170, 1, 255, 1.0);
}


.plc-btn-accepted:hover{
  color: #fff;
  background-color: #AA01FFFF;
}

.plc-round-accepted{
  border-radius: 100px;
  background: rgba(170, 1, 255, 0.1);
  width: 120px;
  height: 120px;
  padding-top: 32px;
  margin: auto auto;
}

.plc-text-dispatched{
  color: rgba(0, 123, 255, 1.0);
}

.plc-badge-dispatched{
  background: rgba(0, 123, 255, 0.1) !important;
  color: rgba(0, 123, 255, 1.0) !important;
  font-weight: normal;
  padding: 4px 16px;
  font-size: 12px;
}

.plc-round-dispatched{
  border-radius: 100px;
  background: rgba(0, 123, 255, 0.1);
  width: 120px;
  height: 120px;
  padding-top: 32px;
  margin: auto auto;
}

.plc-text-delivered{
  color: rgba(0, 201, 167, 1.0);
}

.plc-badge-delivered{
  background: rgba(0, 201, 167, 0.1) !important;
  color: rgba(0, 201, 167, 1.0) !important;
  font-weight: normal;
  padding: 4px 16px;
  font-size: 12px;
}

.plc-round-delivered{
  border-radius: 100px;
  background: rgba(0, 201, 167, 0.1);
  width: 120px;
  height: 120px;
  padding-top: 32px;
  margin: auto auto;
}

.plc-round{
  border-radius: 100px;
  width: 120px;
  height: 120px;
  padding-top: 32px;
  margin: auto auto;
  background: #FBFBFB;
}

.plc-text-pending{
  color: rgba(220, 53, 69, 1.0) !important;
}

.plc-badge-pending{
  background: rgba(220, 53, 69, 0.1) !important;
  color: rgba(220, 53, 69, 1.0) !important;
  font-weight: normal;
  padding: 4px 16px;
  font-size: 12px;
}

.plc-round-pending{
  border-radius: 100px;
  background: rgba(220, 53, 69, 0.1);
  width: 120px;
  height: 120px;
  padding-top: 32px;
  margin: auto auto;
}

.plc-badge-canceled{
  background: rgba(108, 117, 125, 0.1) !important;
  color: rgba(108, 117, 125, 1.0) !important;
  font-weight: normal;
  padding: 4px 16px;
  font-size: 12px;
}

.plc-round-canceled{
  border-radius: 100px;
  background: rgba(108, 117, 125, 0.1);
  width: 120px;
  height: 120px;
  padding-top: 32px;
  margin: auto auto;
}

p{
  font-size: 14px;
}

.alert-custom-success {
  color: #00d959;
  background-color: rgba(0, 217, 89, 0.1);
}

.btn-custom-success {
  color: #fff;
  background-color: rgba(0, 217, 89, .9);
}

.btn-custom-success:hover{
  color: #fff;
  background-color: #00d959;
}


.cart-counter {
  position: absolute;
  min-height: 16px;
  line-height: 16px;
  top: 0;
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  border-radius: 99px;
  color: #fff;
  background-color: #00d959;
  padding: 0 5px;
  text-align: center;
  font-size: 10px;
}

.cart-size {
  font-size: 28px;
  cursor: pointer;
}

.cart-empty{
  width: 68px;
  height: 68px;
  line-height: 80px;
  margin: 32px auto;
  text-align: center;
  border: 1px solid rgba(0, 201, 167, 0.1);
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  border-radius: 99px;
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
}

.main-menu{
  font-size: 14px;
  margin-bottom: 0;
}

.main-menu li{
  display: inline;
}

.main-menu-mobile{
  margin-top: 32px;
}

.main-menu-mobile a{
  font-size: 18px !important;
}

.main-menu-mobile li{
  padding: 8px 0 !important;
}

a {
  font-size: 14px;
}



.add-to-cart-link{
  opacity: 0;
}

.carousel-container{
  /*background-image: linear-gradient(150deg, #eeb400, #F0E27B) !important;*/
  background-color: #eee !important;
  /*background-image: linear-gradient(150deg, #00c9a7, #ffffff);*/
  /*background-color: rgba(0, 201, 167, 0.1);*/
  /*background-color: #00c9a7;*/
}

.gradient-background{
  background-image: linear-gradient(150deg, #eeb400, #ffd100) !important;
}

.placental-serum-background-color{
  background-color: #f53739;
}

.carousel img{
  max-height: 380px;
  visibility: hidden;
}

.carousel-item{
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}

footer{
  font-size: 12px;
}

footer .row{
  max-width: 1024px;
  margin: 0 auto;
}

footer p{
  font-size: 12px;
}

footer a{
  font-size: 12px;
}

.header button {
  font-size: 14px;
}

.header a{
  color: #051945;
}

.header .row{
  max-width: 1024px;
  margin: 0 auto;
}

.user-zone .btn-link{
  color: #051945;
  font-size: 14px;
}

.user-zone .btn-link:hover{
  color: #051945;
}

.masonry{

}

.masonry .card{
  border: none;
}


.u-btn-white--air {
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
  border-color: transparent;
}

.transition-3d-hover {
  transition: all 0.2s ease-in-out;
}

.add-remove-cart .fa{
  cursor: pointer;
}

.form-control-lg{
  font-size: 16px;
  min-height: 48px;
}

.order-summary p {
  font-size: 16px;
}

.breadcrumbs a {
  font-size: 12px;
}

.iziToast:after {
  box-shadow: none !important;
}

.plc-admin-bg {
  color: #041e3a;
  padding: 32px;
  min-height: 100%;
}

.plc-admin-bg table{
  color: #041e3a;
}

.plc-admin-bg table th{
  font-size: 16px;
  border-bottom: 1px solid #041e3a;
}

.plc-badge-btn{
  background: #003478;
  color: #fff;
  font-weight: normal;
  padding: 4px 16px;
  font-size: 14px;
}

.plc-badge-btn:hover{
  color: #fff;
}


.plc-badge-action{
  color: #041e3a;
  font-weight: normal;
  padding: 4px 16px;
  font-size: 14px;
}

.plc-badge-action-delete{
  color: #fff;
  background: rgba(220, 53, 69, 1.0) !important;
  font-weight: normal;
  padding: 4px 16px;
  font-size: 12px;
}
.plc-badge-action-delete:hover{
  color: #fff;
}

.order-summary-color table{
  color: #041e3a !important;
}

.order-summary-color p{
  color: #041e3a !important;
  font-size: 15px;
}

a.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.4;
}

.plc-alert-warning {
  background: rgba(255, 211, 0, 0.1) !important;
  color: rgba(255, 211, 0, 1.0) !important;
}
.content .row{
  max-width: 1024px;
  margin: 0 auto;
}

.top-menu .row{
  max-width: 1024px;
  margin: 0 auto;
  color: #051945;
}
.top-menu a{
  color: #051945;
}

.best-price{
  width: 0;
  height: 0;
  border-style: solid;

  border-width: 96px 96px 0 0;
  border-color: #c70000 transparent transparent transparent;
  left: 40px;
  top: 40px;
  position: absolute;
  /*text-align: right !important;*/
}

.best-price-text{
  position: absolute;
  font-weight: bold;
  font-size: 36px;
  top: 42px;
  left: 52px;
  color: #fff;
}

.best-price-carousel{
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 96px 96px 0;
  border-color: transparent #c70000 transparent transparent;
  right: 0px;
  top: 0px;
  position: absolute;
  text-align: right !important;
}

.best-price-text-carousel{
  position: absolute;
  font-weight: bold;
  font-size: 36px;
  top: 6px;
  right: 6px;
  color: #fff;
}

.success-icon {
  display: inline-block;
  width: 8em;
  height: 8em;
  font-size: 10px;
  border-radius: 50%;
  border: 3px solid #00d959;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  transform-origin: center;
  animation: showSuccess 380ms ease-in-out;
  transform: scale(1);
}
.success-icon__tip, .success-icon__long {
  display: block;
  position: absolute;
  height: 3px;
  background-color: #00d959;
  border-radius: 10px;
}
.success-icon__tip {
  width: 2.4em;
  top: 4.3em;
  left: 1.4em;
  transform: rotate(45deg);
  animation: tipInPlace 400ms ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 280ms;
  visibility: hidden;
}
.success-icon__long {
  width: 4em;
  transform: rotate(-45deg);
  top: 3.7em;
  left: 2.75em;
  animation: longInPlace 340ms ease-in-out;
  animation-fill-mode: forwards;
  visibility: hidden;
  animation-delay: 440ms;
}
@keyframes showSuccess {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
@keyframes tipInPlace {
  from {
    width: 0em;
    top: 0em;
    left: -1.6em;
  }
  to {
    width: 2.4em;
    top: 4.3em;
    left: 1.4em;
    visibility: visible;
  }
}
@keyframes longInPlace {
  from {
    width: 0em;
    top: 5.1em;
    left: 3.2em;
  }
  to {
    width: 4em;
    top: 3.7em;
    left: 2.75em;
    visibility: visible;
  }
}


.loader {
  width: 48px;
  height: 48px;
  border-width: 3px;
  border-style: dashed solid  solid dotted;
  border-color: #FF3D00 #FF3D00 transparent #FF3D00;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 20px;
  top: 31px;
  border: 10px solid transparent;
  border-right-color: #FF3D00;
  transform: rotate(-40deg);
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



/*svg {*/
/*  width: 5em;*/
/*  display: block;*/
/*  margin: 24px auto;*/
/*}*/
.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}
.path.circle {
  -webkit-animation: dash .9s ease-in-out;
  animation: dash .9s ease-in-out;
}
.path.line {
  stroke-dashoffset: 1000;
  -webkit-animation: dash .9s .35s ease-in-out forwards;
  animation: dash .9s .35s ease-in-out forwards;
}
.path.check {
  stroke-dashoffset: -100;
  -webkit-animation: dash-check .9s .35s ease-in-out forwards;
  animation: dash-check .9s .35s ease-in-out forwards;
}

p.error {
  color: #f53739;
}
@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}


